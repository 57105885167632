import {NgModule} from "@angular/core";
import {CommonModule} from "@angular/common";
import {TagsComponent} from "./components/tags/tags.component";
import {TagsDataService} from "./services/tags-data/tags-data.service";

@NgModule({
	imports: [
		CommonModule
	],
	declarations: [
		TagsComponent
	],
	exports: [
		TagsComponent
	],
	providers: [
		TagsDataService
	]

})
export class TagsModule {
}
