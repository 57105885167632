import {NgModule} from "@angular/core";
import {CommonModule, NgOptimizedImage} from "@angular/common";
import {HomePageComponent} from "./pages/home-page/home-page.component";
import {TranslateModule} from "@ngx-translate/core";
import {ShopModule} from "../shop/shop.module";
import {SocialModule} from "../social/social.module";
import {CommonsModule} from "../commons/commons.module";
import {HomeFiltersComponent} from "./components/home-filters/home-filters.component";
import {NgbModule} from "@ng-bootstrap/ng-bootstrap";
import {FormsModule} from "@angular/forms";
import {TagsModule} from "../tags/tags.module";
import {Route, RouterModule, RouterOutlet, Routes, UrlMatchResult, UrlSegment, UrlSegmentGroup} from "@angular/router";
import {MainFeedComponent} from "./components/main-feed/main-feed.component";
import {InfiniteScrollDirective} from "ngx-infinite-scroll";
import {NgxMasonryModule} from "ngx-masonry";
import {FeedSuggestionsComponent} from "./components/feed-suggestions.component";
import {HomeSearchUrlService} from "./services/home-search-url.service";
import {InlineSVGModule} from "ng-inline-svg-2";
import {ProductPageComponent} from "../shop/modals/product-page/product-page.component";
import {CategorySelectorComponent} from "./components/category-selector.component";
import {PriceSelectorComponent} from "./components/price-selector.component";
import {FeedBreadcrumbsComponent} from "./components/feed-breadcrumbs.component";
import {TypeSelectorComponent} from "./components/type-selector.component";
import {ProductItemComponent} from "./items/product-item.component";
import {ItemSkeletonComponent} from "./items/item-skeleton.component";
import {ScrollingDisabledDirective} from "../commons/directives/scrolling-disabled.directive";

const routes: Routes = [
	{
		path: "",
		redirectTo: "all",
		pathMatch: "full",
	},
	{
		matcher: typCategoryMatcher,
		component: HomePageComponent,
		children: [
			{
				path: "product/:id",
				component: ProductPageComponent
			},
			// {
			// 	matcher: customMatcher,
			// }
		]
	}
];

export function typCategoryMatcher(segments: UrlSegment[], group: UrlSegmentGroup, route: Route): UrlMatchResult {
	if (!segments[0]) {
		return { consumed: [] };
	}

	if (segments[0].path === "product") {
		return { consumed: segments };
	}

	const productIndex = segments.findIndex(s => s.path === "product");

	if (productIndex !== -1) {
		const consumed = segments.slice(0, segments.findIndex(s => s.path === "product"));
		return {
			consumed,
			posParams: buildPosParams(consumed)
		};
	}

	return {
		consumed: segments,
		posParams: buildPosParams(segments)
	};
}

function buildPosParams(segments?: UrlSegment[]) {
	if (!segments) {
		return null;
	}

	const rawParams = {
		types: segments[0],
		category: segments[1],
		subCategory: segments[2],
		subSubCategory: segments[3]
	};

	// that way we make sure the object doesn't contain undefined as a value
	return Object.fromEntries(Object.entries(rawParams).filter(o => !!o[1]));
}

@NgModule({
	imports: [
		CommonModule,
		RouterModule.forChild(routes),
		TranslateModule.forChild(),
		NgbModule,
		ShopModule,
		SocialModule,
		TagsModule,
		CommonsModule,
		FormsModule,
		NgxMasonryModule,
		InlineSVGModule,

		// scroll paging
		InfiniteScrollDirective,
		PriceSelectorComponent,
		FeedSuggestionsComponent,
		FeedBreadcrumbsComponent,
		TypeSelectorComponent,
		CategorySelectorComponent,
		ProductItemComponent,
		ItemSkeletonComponent,
		NgOptimizedImage,
		RouterOutlet,
		ScrollingDisabledDirective
	],
	declarations: [
		HomePageComponent,
		MainFeedComponent,
		HomeFiltersComponent,
	],
	providers: [
		HomeSearchUrlService,
		{
			provide: "Window",
			useValue: window
		}
	]
})
export class HomeModule {
}
