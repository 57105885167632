import {Component, computed, input, Input, model} from "@angular/core";
import {FormsModule} from "@angular/forms";

@Component({
	selector: "app-price-input",
	standalone: true,
	imports: [
		FormsModule
	],
	template: `<div class="nui input-container">
		<div class="label-container">
			<label>{{label()}}</label>
		</div>

		<div class="input-with-icon">
			<i>£</i>
			<input type="number"
						 [min]="0"
						 [max]="1000"
						 [(ngModel)]="price"
			>
		</div>
	</div>`,
})
export class  PriceInputComponent {
	label = input.required<string>();
	price = model.required<number>();
}
