<div [ngClass]="{ 'sidebar-left-hidden' : !isLeftActive, 'sidebar-right-hidden' : !isRightActive }" id="page-container">
	<div class="position-relative">
		<aside id="left-sidebar"
		       class="sidebar w-4/5 overscroll-contain max-xs:min-w-0 max-md:min-w-72 md:w-72"
		       [class.left-0]="homeLayoutService.isMd() && homeLayoutService.isMobileSidebarOpen()"
		       [class.-left-full]="homeLayoutService.isMd()">
			<app-home-filters></app-home-filters>
		</aside>

		<main id="content-wrapper" [appScrollingDisabled]="isMdSidebarMode()">
			@if (isMdSidebarMode()) {
				<div
					class="fixed inset-0 bg-black opacity-50 z-10"
					(click)="homeLayoutService.toggleMobileSidebar()"
				></div>
			}
			<app-main-feed></app-main-feed>
		</main>

		<router-outlet></router-outlet>

		<aside id="right-sidebar" class="sidebar w-72">
			<app-social-feed></app-social-feed>
		</aside>

		<!--		<div class="inner-container">-->
		<!--		</div>-->
	</div>

</div>
