import {Component, computed, inject, OnInit} from "@angular/core";
import {HomeLayoutService} from "../../services/home-layout.service";

@Component({
	selector: "app-home-page",
	templateUrl: "./home-page.component.html",
	styleUrls: ["./home-page.component.scss"],
})
export class HomePageComponent implements OnInit {
	readonly homeLayoutService = inject(HomeLayoutService);
	isLeftActive = true;
	isRightActive = true;

	ngOnInit(): void {
	}

	isMdSidebarMode = computed(() => {
		return this.homeLayoutService.isMd() && this.homeLayoutService.isMobileSidebarOpen()
	})
}
