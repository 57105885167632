import {Component, inject, input} from "@angular/core";
import {Product} from "../../shared/model/data/shop/product/Product";
import {DecimalPipe, NgOptimizedImage} from "@angular/common";
import {ActivatedRoute, RouterLink} from "@angular/router";

@Component({
	selector: "app-product-item",
	standalone: true,
	imports: [
		NgOptimizedImage,
		RouterLink,
		DecimalPipe
	],
	template: `
		<a class="block relative" [routerLink]="['product', product()._id]" [relativeTo]="activatedRoute">
			<img class="object-contain bg-white cursor-pointer h-auto w-auto min-w-full rounded-lg shadow-sm"
				 [src]="product().variants[0]?.media[0]?.urlLarge || '../../../assets/images/image-placeholder.png'">

			<a class="item-buy-now btn btn-primary text-uppercase">
				<!--price (on mobile)-->
				<span class="item-price-alt">
				{{product().variants[0].currency}} {{product().variants[0].price | number:'1.2-2'}}
				</span>
			</a>
		</a>

		<p class="!mt-2.5 text-gray-primary font-medium leading-tight hover:underline">
			<a [routerLink]="['product', product()._id]" [relativeTo]="activatedRoute">
				{{product().title}}
			</a>
		</p>

		<p class="mt-0.5 text-gray-primary font-medium">from <a class="text-pink-primary hover:underline" [routerLink]="['/user', product().user.userName, product().user._id]">{{product().user.displayName}}</a></p>
	`,
	styles: [`
		:host {
			display: block;
		}

		.item-buy-now{
			position: absolute;
			color: white;

			bottom: 10px;
			right: 10px;

			font-weight: 500;
			font-size: 1rem;

			padding-top: .5em;
			padding-bottom: .5em;

			height: auto;
			background: linear-gradient(45deg, #DA3D6A, #e8561d);

			transition: all .5s ease;

			&:hover {
				background-size: 200% 100%;
				background-position-x: 100%;
			}
		}`]
})
export class ProductItemComponent {
	product = input.required<Product>();
	isLCP = input<boolean>(false);

	readonly activatedRoute = inject(ActivatedRoute);
}
