import {AfterContentChecked, Component, computed, effect, inject, viewChild} from "@angular/core";
import {HomeFeedService} from "../../services/home-feed.service";
import {ActivatedRoute} from "@angular/router";
import {takeUntilDestroyed, toSignal} from "@angular/core/rxjs-interop";
import {combineLatest, shareReplay} from "rxjs";
import {map} from "rxjs/operators";
import {ContentType} from "../../../shared/model/data/enums/ContentType";
import {Product} from "../../../shared/model/data/shop/product/Product";
import {Content} from "../../../shared/model/content/Content";
import {NgxMasonryComponent} from "ngx-masonry";

@Component({
	selector: "app-main-feed",
	templateUrl: "./main-feed.component.html",
	styleUrls: ["./main-feed.component.scss"]
})
export class MainFeedComponent {
	private feedService = inject(HomeFeedService);
	private route = inject(ActivatedRoute);

	private masonry = viewChild(NgxMasonryComponent);

	private filters$ = combineLatest([this.route.params, this.route.queryParams])
		.pipe(
			takeUntilDestroyed(),
			map(([params, queryParams]) => ({
				...params,
				...queryParams,
				types: params.types === "all" ? undefined : params.types
			})),
			map(filters => Object.fromEntries(Object.entries(filters).filter(([_, v]) => !!v))),
			shareReplay()
		);

	private isSuggestionsShown$ = this.filters$.pipe(
		map(Object.keys),
		map(activeFilters => activeFilters.length === 1 && activeFilters[0] === "text")
	);

	isSuggestionsShown = toSignal(this.isSuggestionsShown$);
	products = this.feedService.items;

	constructor() {
		this.filters$.subscribe(filters => {
			this.feedService.setAllFilters(filters);
			window.scrollTo(0, 0);
			setTimeout(() => {
				this.masonry()?.reloadItems();
			}, 0)
		});
	}

	getProduct(item: Content): Product {
		return (item as Product);
	}

	protected readonly ContentType = ContentType;
}
