import {computed, inject, Injectable, Signal, signal} from "@angular/core";
import {ApiCommunicationService} from "../../shared/model/services/api-communication/api-communication.service";
import {injectQuery} from "@tanstack/angular-query-experimental";
import {lastValueFrom} from "rxjs";
import {ContentType} from "../../shared/model/data/enums/ContentType";
import {DomSanitizer, SafeHtml} from "@angular/platform-browser";
import {Category} from "../../shared/model/data/shop/category/Category";
import {TranslateService} from "@ngx-translate/core";
import {toSignal} from "@angular/core/rxjs-interop";

export type NavItem = {
	segment: string;
	children?: NavItem[];
	parent?: NavItem,
	displayedName?: Signal<string>;
	img?: SafeHtml;
}

const TYPES = [
	{
		segment: "all",
		svg: `<svg id="Group_4" data-name="Group 4" xmlns="http://www.w3.org/2000/svg" width="13" height="12.992" viewBox="0 0 13 12.992"><path id="Path_7" data-name="Path 7" d="M9.208,9.9v3.25h2.167A1.625,1.625,0,0,0,13,11.527V6.586a1.083,1.083,0,0,0-.3-.754L8.092.856A2.167,2.167,0,0,0,5.03.735Q4.968.793,4.91.856L.315,5.831A1.083,1.083,0,0,0,0,6.594v4.932a1.625,1.625,0,0,0,1.625,1.625H3.792V9.9a2.708,2.708,0,1,1,5.417,0Z" transform="translate(0 -0.16)" fill="#707070"/><path id="Path_8" data-name="Path 8" d="M193.625,319.841A1.625,1.625,0,0,0,192,321.466v3.25h3.25v-3.25A1.625,1.625,0,0,0,193.625,319.841Z" transform="translate(-187.125 -311.724)" fill="#707070"/></svg>`,
	},
	{
		segment: ContentType.PRODUCT,
		svg: `<svg xmlns="http://www.w3.org/2000/svg" width="10.41" height="12.492" viewBox="0 0 10.41 12.492"><path id="label" d="M7.2,0,2,2.874V10.93a1.561,1.561,0,0,0,1.561,1.561h7.287A1.566,1.566,0,0,0,12.41,10.93V2.874Zm0,4.424a.781.781,0,1,1,.781-.781A.781.781,0,0,1,7.2,4.424Z" transform="translate(-2)" fill="#707070"/></svg>`
	},
	{
		segment: ContentType.POST,
		svg: `<svg xmlns="http://www.w3.org/2000/svg" width="13.354" height="13.354" viewBox="0 0 13.354 13.354"><path id="comment-alt" d="M11.129,0h-8.9A2.226,2.226,0,0,0,0,2.226V8.9a2.226,2.226,0,0,0,2.226,2.226H3.839l2.477,2.094a.556.556,0,0,0,.719,0l2.48-2.094h1.614A2.226,2.226,0,0,0,13.354,8.9V2.226A2.226,2.226,0,0,0,11.129,0ZM3.9,2.782H6.677a.556.556,0,0,1,0,1.113H3.9a.556.556,0,0,1,0-1.113ZM9.459,8.347H3.9a.556.556,0,1,1,0-1.113H9.459a.556.556,0,1,1,0,1.113Zm0-2.226H3.9a.556.556,0,1,1,0-1.113H9.459a.556.556,0,1,1,0,1.113Z" fill="#707070"/></svg>`
	},
	{
		segment: ContentType.EVENT,
		svg: `<svg xmlns="http://www.w3.org/2000/svg" width="12.632" height="12.632" viewBox="0 0 12.632 12.632"><path id="Path_9" data-name="Path 9" d="M0,14.737a2.635,2.635,0,0,0,2.632,2.632H10a2.635,2.635,0,0,0,2.632-2.632V10H0Zm8.948-2.369a.79.79,0,1,1-.79.79A.79.79,0,0,1,8.948,12.369Zm-2.632,0a.79.79,0,1,1-.79.79A.79.79,0,0,1,6.316,12.369Zm-2.632,0a.79.79,0,1,1-.79.79.79.79,0,0,1,.79-.79Z" transform="translate(0 -4.737)" fill="#707070"/><path id="Path_10" data-name="Path 10" d="M10,1.053H9.474V.526a.526.526,0,0,0-1.053,0v.526H4.211V.526a.526.526,0,1,0-1.053,0v.526H2.632A2.635,2.635,0,0,0,0,3.684v.526H12.632V3.684A2.635,2.635,0,0,0,10,1.053Z" fill="#707070"/></svg>`
	},
	{
		segment: ContentType.MICROBLOGPOST,
		svg: `<svg id="edit_1_" data-name="edit (1)" xmlns="http://www.w3.org/2000/svg" width="13.354" height="13.354" viewBox="0 0 13.354 13.354"><path id="Path_22" data-name="Path 22" d="M18,18.556v2.615a2.764,2.764,0,0,0,1.046-.648l1.478-1.479A2.757,2.757,0,0,0,21.172,18H18.556A.556.556,0,0,0,18,18.556Z" transform="translate(-7.984 -7.984)" fill="#707070"/><path id="Path_23" data-name="Path 23" d="M6.652,8.528A2.226,2.226,0,0,0,6,10.1v.747h.747A2.226,2.226,0,0,0,8.322,10.2l6.3-6.3a1.18,1.18,0,0,0-1.669-1.669Z" transform="translate(-2.661 -0.833)" fill="#707070"/><path id="Path_24" data-name="Path 24" d="M13.354,2.755a2.274,2.274,0,0,1-.6,1.092l-6.307,6.3a3.317,3.317,0,0,1-2.361.978H3.339a1.113,1.113,0,0,1-1.113-1.113V9.269A3.315,3.315,0,0,1,3.2,6.908L9.507.6A2.269,2.269,0,0,1,10.593,0H2.782A2.786,2.786,0,0,0,0,2.782v7.79a2.786,2.786,0,0,0,2.782,2.782H8.9V10.572A1.669,1.669,0,0,1,10.572,8.9h2.782V2.755Z" fill="#707070"/></svg>`
	}
];

@Injectable({
	providedIn: "root"
})
export class ProductsNavigationService {
	private readonly api = inject(ApiCommunicationService);
	private readonly translateService = inject(TranslateService);

	categories = injectQuery(() => {
		return {
			queryKey: ["categories"],
			queryFn: async () => {
				return lastValueFrom(this.api.category().getCategories());
			},
			staleTime: Infinity
		};
	});

	fullCategoryPaths = computed(() => {
		const getPaths = (category: Category, path = []) => {
			const currentPath = [...path, category._id];
			const subCategoryPaths = category.subCategories?.flatMap(subCategory =>
				getPaths(subCategory, currentPath)
			) || [];
			return [currentPath, ...subCategoryPaths];
		};

		return this.categories.data()?.flatMap(category => getPaths(category));
	});

	navTypes = signal<NavItem[]>(TYPES.map(type => ({
		...type,
		img: inject(DomSanitizer).bypassSecurityTrustHtml(type.svg),
		displayedName: toSignal(this.translateService.get("home.filters.type.values." + type.segment))
	}))).asReadonly();

	fullNavigation = computed(() => {
		const categories = this.categories.data();

		if (!categories) {
			return [];
		}

		return this.navTypes().map(type => {
			if (type.segment === ContentType.PRODUCT) {
				type.children = categories.map(category => buildCategoryNavItem(category, type));
			}
			return type;
		});
	})


}

function buildCategoryNavItem(category: Category, parent?: NavItem): NavItem {
	const navItem: NavItem = {
		segment: category._id,
		parent,
		displayedName: signal(category.name)
	}

	navItem.children = category.subCategories.map(sub => buildCategoryNavItem(sub, navItem));

	return navItem;
}
