import {ChangeDetectionStrategy, Component, computed, effect, inject} from "@angular/core";
import {FormsModule} from "@angular/forms";
import {ActivatedRoute, Router} from "@angular/router";
import {toSignal} from "@angular/core/rxjs-interop";
import {PriceInputComponent} from "./price-input.component";
import {SearchRequest} from "../../shared/model/request/search/SearchRequest";

@Component({
	selector: "app-price-selector",
	standalone: true,
	template: `
		<div class="flex gap-2">
			<!--From-->
			<app-price-input
				label="From"
				[price]="bottomPrice()"
				(priceChange)="updatePrice($event, 'bottomPrice')"/>
			<!--To-->
			<app-price-input
				label="To"
				[price]="topPrice()"
				(priceChange)="updatePrice($event, 'topPrice')"/>
		</div>
	`,
	imports: [
		FormsModule,
		PriceInputComponent
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class PriceSelectorComponent {
	private route = inject(ActivatedRoute)
	private router = inject(Router)
	private queryParamMap = toSignal(this.route.queryParamMap);

	bottomPrice = computed(() => parseFloat(this.queryParamMap().get("bottomPrice")));
	topPrice = computed(() => parseFloat(this.queryParamMap().get("topPrice")));

	updatePrice(value: Pick<SearchRequest, 'bottomPrice' | 'topPrice'>, name: string) {
		this.router.navigate([], {
			relativeTo: this.route,
			queryParams: {[name]: value},
			queryParamsHandling: 'merge'}
		)
	}
}
