<div id="home-filters" class="p-6 md:pr-0">

	<!--	<button id="filter-close-button">-->
	<!--		<i class="lovegivr lov-close"></i>-->
	<!--	</button>-->


	<div class="filter-box first-of-type:before:block before:h-[1px] before:w-1/6 before:rounded-lg">
		<div class="p-4 flex flex-col items-start bg-white">
			<app-type-selector/>
		</div>

		<button
			class="w-full mt-4 p-2 text-red-500 bg-red-200 rounded-sm
			disabled:bg-[#DFDFDF] disabled:text-[#7d7d7d]"
			(click)="removeFilters()"
			[disabled]="!isFilterPresent()">Reset filters
		</button>

	</div>


	<div class="filter-box px-2">
		<p class="mb-2 text-center">Product category</p>
		<app-category-selector/>
	</div>

	<div class="filter-box px-2">
		<p class="mb-2 text-center">Filter by Price</p>
		<app-price-selector/>
	</div>

</div>
