import {booleanAttribute, Directive, effect, ElementRef, inject, input} from "@angular/core";

@Directive({
	selector: "[appScrollingDisabled]",
	standalone: true
})
export class ScrollingDisabledDirective {
	private readonly elementRef = inject(ElementRef);
	isDisabled = input(false, { alias: "appScrollingDisabled", transform: booleanAttribute });

	constructor() {
		effect(() => {
			if (this.isDisabled()) {
				disableScroll(this.elementRef.nativeElement);
			} else {
				enableScroll(this.elementRef.nativeElement)
			}

			return () => enableScroll(this.elementRef.nativeElement);
		})
	}

}

// left: 37, up: 38, right: 39, down: 40,
// spacebar: 32, pageup: 33, pagedown: 34, end: 35, home: 36
const keys = { 37: 1, 38: 1, 39: 1, 40: 1 };

function preventDefault(e) {
	e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
	if (keys[e.keyCode]) {
		preventDefault(e);
		return false;
	}
}

// modern Chrome requires { passive: false } when adding event
let supportsPassive = false;
try {
	window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
		get: function () { supportsPassive = true; }
	}));
} catch(e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = "onwheel" in document.createElement("div") ? "wheel" : "mousewheel";

// call this to Disable
function disableScroll(element: HTMLElement) {
	element.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
	element.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
	element.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
	element.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

// call this to Enable
function enableScroll(element: HTMLElement) {
	element.removeEventListener('DOMMouseScroll', preventDefault, false);
	element.removeEventListener(wheelEvent, preventDefault);
	element.removeEventListener('touchmove', preventDefault);
	element.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}
