import {Component, computed, inject} from "@angular/core";
import {ActivatedRoute, Router} from "@angular/router";
import {toSignal} from "@angular/core/rxjs-interop";

const filterParams = ["category", "subcategory", ",subsubcategory"] as const;
const filterQueryParams = ["bottomPrice", "topPrice"] as const;

@Component({
	selector: "app-home-filters",
	templateUrl: "./home-filters.component.html",
	styleUrls: ["./home-filters.component.scss"]
})
export class HomeFiltersComponent {
	private route = inject(ActivatedRoute);
	private router = inject(Router);
	private paramMap = toSignal(this.route.paramMap);
	private queryParamMap = toSignal(this.route.queryParamMap);

	selectedType = computed(() => this.paramMap().get("types"));

	isFilterPresent = computed(() => {
		return filterParams.some(p => this.paramMap().has(p)) || filterQueryParams.some(p => this.queryParamMap()
			.has(p));
	});

	removeFilters() {
		this.router.navigate(["/", this.selectedType()], { queryParams: null });
	}
}
