import {Injectable} from "@angular/core";
import {SearchRequest} from "../../shared/model/request/search/SearchRequest";
import {ActivatedRoute, Router} from "@angular/router";
import {HttpUrlEncodingCodec} from "@angular/common/http";

@Injectable({
	providedIn: "root"
})
export class HomeSearchUrlService {

	private codec = new HttpUrlEncodingCodec;

	constructor(private router: Router,
				private activatedRoute: ActivatedRoute) {
	}

	public setSearchUrlParams(filter: SearchRequest) {
		if (!filter) {
			return;
		}

		const encodedFilter = SearchRequest.serialize(filter);

		this.isEmpty(filter) ?
			this.router.navigate(["/"]).then() :
			this.router.navigate([], {
				relativeTo: this.activatedRoute,
				queryParams: {search: encodedFilter},
				queryParamsHandling: "merge"
			}).then();
	}

	public getSearchFromUrl(): any {

		if (this.activatedRoute.snapshot.queryParams['search']) {
			try {
				return JSON.parse(this.activatedRoute.snapshot.queryParams['search']);
			} catch (e) {
				console.log("Something went wrong during decoding the url params. Error=", e);
			}
		}

		return null;
	}

	private isEmpty(value): boolean {
		return  value === undefined ||
			value === null ||
			(typeof value === "object" && Object.keys(value).length === 0) ||
			(typeof value === "string" && value.trim().length === 0);
	}


}
