import {ChangeDetectionStrategy, Component, inject} from "@angular/core";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {NgClass} from "@angular/common";
import {ProductsNavigationService} from "../services/products-navigation.service";

@Component({
	selector: "app-type-selector",
	template: `
		<section class="types">
			@for (type of types(); track type.segment) {
				<a class="flex items-center justify-start py-2 text-gray-primary cursor-pointer"
				   [ngClass]="{'pt-2': $first, 'pb-2': $last}"
				   [routerLink]="['/' + type.segment]"
				   routerLinkActive="text-pink-primary"
				   #rla="routerLinkActive">
					<div class="w-6 inline-flex items-center justify-center mr-4"
						 [class.*:*:fill-pink-primary]="rla.isActive"
						 [innerHTML]="type.img">
					</div>
					<span>{{type.displayedName()}}</span>
				</a>
			}
		</section>

		<ng-template #icon></ng-template>

	`,
	standalone: true,
	imports: [
		RouterLink,
		RouterLinkActive,
		NgClass
	],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeSelectorComponent {
	types = inject(ProductsNavigationService).navTypes;
}
