import { Component } from '@angular/core';

@Component({
  selector: 'app-item-skeleton',
  standalone: true,
  imports: [],
  template: `
	  <div class="w-100 bg-[#ccc] aspect-square rounded-lg"></div>
	  <div class="w-40 h-4 bg-[#ccc] !mt-3"></div>
	  <div class="w-40 h-4 bg-[#ccc] !mt-2"></div>
  `
})
export class ItemSkeletonComponent {
}
