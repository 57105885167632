import {Component, inject, input} from "@angular/core";
import {Category} from "../../shared/model/data/shop/category/Category";
import {ContentType} from "../../shared/model/data/enums/ContentType";
import {RouterLink, RouterLinkActive} from "@angular/router";
import {ProductsNavigationService} from "../services/products-navigation.service";

@Component({
	selector: "app-categories-level",
	host: { class: "block" },
	standalone: true,
	imports: [
		RouterLink,
		RouterLinkActive
	],
	template: `
		@for (category of categories(); track category._id) {
			<a class="flex flex-col items-start
								group peer"
			   [attr.data-id]="category._id"
			   [attr.data-active]="rla.isActive"
			   [routerLink]="parents().concat(category._id)"
			   (click)="$event.stopPropagation()"
			   routerLinkActive
			   #rla="routerLinkActive">
				<span class="mb-2 text-[#707070]
										 group-data-[active=true]:text-[#D31148] group-data-[active=true]:font-semibold">
					@if (category.subCategories.length; as subcategoriesLength) {
						<span>{{rla.isActive ? '-' : '+'}}</span>
					}

					{{category.name}}
				</span>
			</a>

			@if (rla.isActive) {
				<app-categories-level class="ml-6"
									  [parents]="parents().concat(category._id)"
									  [categories]="category.subCategories"/>
			}
		}
	`
})
export class CategoriesLevelComponent {
	parents = input<string[]>();
	categories = input<Category[]>();

	protected readonly ContentType = ContentType;
}

@Component({
	selector: "app-category-selector",
	host: { class: "block" },
	standalone: true,
	imports: [
		CategoriesLevelComponent
	],
	template: `
		<app-categories-level [parents]="['/', ContentType.PRODUCT]"
							  [categories]="categories.data()"></app-categories-level>
	`
})
export class CategorySelectorComponent {
	categories = inject(ProductsNavigationService).categories;

	protected readonly ContentType = ContentType;
}
