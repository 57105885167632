import {Component, computed, inject, signal} from "@angular/core";
import {ActivatedRoute, RouterLink} from "@angular/router";
import {toSignal} from "@angular/core/rxjs-interop";
import {AsyncPipe, JsonPipe, NgClass} from "@angular/common";
import {map} from "rxjs/operators";
import {NavItem, ProductsNavigationService} from "../services/products-navigation.service";

type Breadcrumb = NavItem & { routerLink: string[] };

@Component({
	selector: "app-feed-breadcrumbs",
	standalone: true,
	imports: [
		JsonPipe,
		AsyncPipe,
		RouterLink,
		NgClass
	],
	template: `
		@if (breadcrumbs()) {
			<nav aria-label="breadcrumb">
				<ol class="inline-flex space-x-3 w-full overflow-hidden">
					<li class="inline-flex items-center text-neutral-500 group">
						<a class="text-sm"
						   [routerLink]="['/']">
							<svg id="Group_4" data-name="Group 4" xmlns="http://www.w3.org/2000/svg" width="13"
								 height="12.992" viewBox="0 0 13 12.992">
								<path id="Path_7" data-name="Path 7"
									  d="M9.208,9.9v3.25h2.167A1.625,1.625,0,0,0,13,11.527V6.586a1.083,1.083,0,0,0-.3-.754L8.092.856A2.167,2.167,0,0,0,5.03.735Q4.968.793,4.91.856L.315,5.831A1.083,1.083,0,0,0,0,6.594v4.932a1.625,1.625,0,0,0,1.625,1.625H3.792V9.9a2.708,2.708,0,1,1,5.417,0Z"
									  transform="translate(0 -0.16)" fill="#707070"/>
								<path id="Path_8" data-name="Path 8"
									  d="M193.625,319.841A1.625,1.625,0,0,0,192,321.466v3.25h3.25v-3.25A1.625,1.625,0,0,0,193.625,319.841Z"
									  transform="translate(-187.125 -311.724)" fill="#707070"/>
							</svg>
						</a>
					</li>
					@for (breadcrumb of breadcrumbs(); track breadcrumb.segment) {
						<li class="items-center text-neutral-500 space-x-3 whitespace-nowrap"
							[ngClass]="{'overflow-hidden text-ellipsis': !$last}">
							<span class="text-neutral-400">/</span>

							<a [class.text-decoration-underline]="!$last"
							   [routerLink]="$last ? null : ['/'].concat(breadcrumb.routerLink)"
							>{{breadcrumb.displayedName()}}</a>
						</li>
					}
				</ol>
			</nav>
		}
	`,
	styles: `
		:host {
			display: block;
		}
	`
})
export class FeedBreadcrumbsComponent {
	private readonly paths$ = inject(ActivatedRoute).url.pipe(
		map(segments => segments.map(segment => segment.path))
	);

	private paths = toSignal(this.paths$);
	private fullNavigation = inject(ProductsNavigationService).fullNavigation;

	breadcrumbs = computed(() => {
		return this.paths().reduce((results, path, index) => {
			const breadcrumb = this.getBreadcrumb(index === 0 ? this.fullNavigation() : results[index - 1].children, path);

			return results.concat(breadcrumb);
		}, [] as Breadcrumb[]);
	});

	getBreadcrumb(navigationBranch: NavItem[] | undefined, segment: string): Breadcrumb {

		const breadcrumb = navigationBranch.find(item => item.segment === segment);

		if (!breadcrumb) {
			return {
				routerLink: ["/"],
				segment,
				displayedName: signal("..."),
				children: []
			};
		}

		return {
			...breadcrumb,
			routerLink: unwrapLink(breadcrumb)
		};
	}
}

function unwrapLink(item: NavItem): string[] {
	if (!item.parent) {
		return [item.segment];
	}

	return unwrapLink(item.parent).concat(item.segment);
}
