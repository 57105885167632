import {Component, inject} from "@angular/core";
import {JsonPipe, NgOptimizedImage} from "@angular/common";
import {HomeFeedService} from "../services/home-feed.service";
import {RouterLink} from "@angular/router";
import {ContentType} from "../../shared/model/data/enums/ContentType";
import {ProductsNavigationService} from "../services/products-navigation.service";

@Component({
	selector: "app-feed-suggestions",
	standalone: true,
	template: `
		@if (suggestions.data()?.categories; as categories) {
			@if (categories.length) {
				<h2 class="text-2xl">Recommended categories</h2>
				<div class="mt-3">
					@for (category of categories; track category._id) {
						@let path = getPath(fullCategoryPaths(), category._id);

						<a [routerLink]="['/', ContentType.PRODUCT].concat(path)"
						   class="bg-rose-100 hover:bg-rose-200 text-rose-700 text-sm font-medium me-2 mb-2 px-3 py-1 rounded-md inline-flex items-center justify-center">
							{{category.name}}
						</a>
					}
				</div>
			}
		}

		@if (suggestions.data()?.users; as users) {
			@if (users.length) {
				<h2 class="mt-4 text-2xl">Users</h2>

				<div class="mt-3 flex gap-3">
					@for (user of users; track user._id) {
						<a class="p-4 px-2 border border-gray-200 rounded-lg shadow"
						   [routerLink]="['/user', user.userName, user._id]">
							<div class="flex flex-col items-center gap-2">
								<img class="w-20 h-20 rounded-full"
									 [src]="user.profileImage?.urlSmall ?? '../../../assets/images/user-default.svg'"
									 alt="">
								<p class="font-medium text-center">
									{{user.displayName}}
								</p>
							</div>
						</a>
					}
				</div>
			}
		}
	`,
	styles: `
		:host {
			display: flex;
			flex-direction: column;

			&:empty {
				display: none;
			}
		}
	`,
	imports: [
		JsonPipe,
		NgOptimizedImage,
		RouterLink
	]
})
export class FeedSuggestionsComponent {
	private readonly feedService = inject(HomeFeedService);
	suggestions = this.feedService.suggestions;
	protected readonly ContentType = ContentType;

	fullCategoryPaths = inject(ProductsNavigationService).fullCategoryPaths;

	getPath(paths: string[][], value: string) {
		return paths.find(path => path.lastIndexOf(value) === path.length - 1);
	}
}
