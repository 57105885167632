<nav class="space-y-4 flex flex-col">
	<app-feed-breadcrumbs/>

	@if (isSuggestionsShown()) {
		<app-feed-suggestions></app-feed-suggestions>
	}
</nav>

@if (!products.isLoading()) {
	@if (!products.data()?.pages[0].docs.length) {
		<div class="rounded-md bg-white p-3 text-sm text-[#707070]">Hmm... Nothing else here! Maybe look for something
			else?
		</div>
	} @else {
		<section
			[infiniteScrollDisabled]="products.isFetchingNextPage()"
			[infiniteScrollDistance]="1"
			[infiniteScrollThrottle]="10"
			class="mb-8 -m-2"
			id="main-feed-wrapper"
			infiniteScroll
			(scrolled)="products.fetchNextPage(); masonry.reloadItems()">

			<ngx-masonry class="*:w-full *:px-2 *:mb-8
								xs:*:w-1/2
								lg:*:w-1/3
								2xl:*:w-1/4"
			             [options]="{percentPosition: true}"
			             [ordered]="true"
			             #masonry>
				@if (products.data()?.pages; as productPages) {
					@for (page of productPages; track page.page) {
						@for (element of page.docs; track element._id) {
							@if (getProduct(element); as product; ) {
								@if (product.brandName) {
									<app-product-item ngxMasonryItem [product]="product"
									                  [isLCP]="$index === 0 || $index === 1"/>
									<!--												<app-product-feed-item [product]="product" [resize]="true"/>-->
								}

							}
							@switch (element.type) {
								@case (ContentType.POST) {
									<app-conversations-feed-item ngxMasonryItem [data]="element"/>
								}
								@case (ContentType.MICROBLOGPOST) {
									<app-conversations-feed-item ngxMasonryItem [data]="element"/>
								}
								@case (ContentType.EVENT) {
									<app-event-feed-item ngxMasonryItem [eventItem]="element"/>
								}
							}
						}
					}
				}
			</ngx-masonry>
		</section>
	}
}
